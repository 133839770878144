import { Component, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AccountService } from '@app/services/accounting/accountService';
import { FinancialKpiDefinitions } from './kpidefinitions';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import {
    TextGenerationSettingsModal,
    TextGenerationSettings,
    TextGenerationLanguages,
} from './textGenerationSettingsModal';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import {
    CommentType,
    FinancialReportCommentService,
    TemplateType,
} from '@app/services/accounting/financialReportCommentService';
import { FinancialReportComment } from '@uni-entities';
import { TextTemplatesImportModal } from './text-templates-import-modal/text-templates-import-modal';
import { AIGenerateService } from '@app/services/common/aiGenerateService';

interface KpiMeta {
    name: string;
    label: string;
    value: number;
    priority?: number;
    valueLastYear?: number;
    icon?: string;
}

@Component({
    selector: 'report-comment-editor',
    templateUrl: './reportCommentEditor.html',
    styleUrls: ['./reportCommentEditor.sass'],
})
export class ReportCommentEditor {
    @Input() id: number;
    @Input() visible: boolean;
    @Input() parameters: Array<{ name: string; value: number | string }>;
    @Input() hasAI: boolean = false;
    @ViewChild('editor') editor: ElementRef;

    kpiList: KpiMeta[];

    currentYear: number;
    kpiMonths: { from: number; to: number; label?: string };

    hasChanges = false;
    companyType: any;
    comments: FinancialReportComment[];
    textGenerationSettings: TextGenerationSettings = {
        ShortComments: true,
        AddIntroText: false,
        AddExtroText: false,
        Language: TextGenerationLanguages[0],
        HelpLabel: 'Les mer om hvordan KI benyttes i våre systemer',
        HelpText:
            'Vår KI-motor benytter OpenAI sitt API med GPT-teknologi. Teknologien drives på Uni Micro AS sitt lukkede Microsoft Azure-miljø som kjøres i Norge. Ingen kundedata deles med andre parter. Merk at innholdet som genereres av KI-teknologi kan variere i kvalitet. Uni Micro AS kan ikke garantere for innholdet og det kan anbefales å alltid verifisere innholdet før det tas i bruk.',
    };

    saving = false;
    loading = false;
    loadingKpi = false;
    generating = false;
    cancel = false;
    icon: string = 'delete';
    storedEditorSelection: Range | null = null;

    constructor(
        private financialReportCommentService: FinancialReportCommentService,
        private accountService: AccountService,
        private errorService: ErrorService,
        private numberFormat: NumberFormat,
        private toastService: ToastService,
        private companySettingsService: CompanySettingsService,
        private uniModalService: UniModalService,
        private aiGenerateService: AIGenerateService,
    ) {}

    ngOnChanges() {
        if (!this.visible) return;

        const updatedPeriod = this.getMonthsFromPeriod();

        if (
            (this.currentYear !== this.id && this.id > 0) ||
            (updatedPeriod &&
                this.kpiMonths &&
                (updatedPeriod.from != this.kpiMonths.from || updatedPeriod.to != this.kpiMonths.to))
        ) {
            this.loadKpis();
            this.loadCompanyType();
            this.loadAndShowComments();
            this.financialReportCommentService.loadTemplates();
        }
    }

    private async loadCompanyType() {
        const settings = await this.companySettingsService.getCompanySettings().toPromise();
        if (settings?.CompanyTypeID) {
            this.companyType = await this.companySettingsService.getCompanyType(settings.CompanyTypeID).toPromise();
        }
    }

    closeRunningProcesses() {
        if (!this.cancel) this.toastService.addToast('Avslutter tekstgenerering...', ToastType.info, 3);
        this.cancel = true;
        this.generating = false;
    }

    saveComments(): Observable<void> {
        const update = this.mergeNewCommentsIntoExisting();
        return this.financialReportCommentService.saveAll(update).pipe(tap(() => this.loadAndShowComments()));
    }

    async startTextGeneration() {
        if (this.generating) {
            this.cancel = true;
            return;
        }

        if (!(this.kpiList?.length > 0)) return;

        this.clearEditor();

        this.generating = true;
        this.cancel = false;

        const commentOrder = [
            'Turnover',
            'CostOfGoodsSold',
            'GrossProfit',
            'SalaryCosts',
            'OperatingResult',
            'LiquidityRatios2',
            'Financials',
            'EarningsBeforeTaxes',
            'EquityAssetsRatio',
            'WorkingCapital',
        ];

        var numberOfSuggestions = 0;

        const periodEndDate = new Date(this.currentYear, this.kpiMonths.to, 0);

        let titles = ['Innledning', 'Oppsummering', 'Kommentarer', 'Rådgivning'];
        const titleIndexes = { Intro: 0, Summary: 1, Comments: 2, Advisory: 3 };
        const offset = Object.keys(titleIndexes).length;

        // Translatations
        let translateTo = '';
        if (this.textGenerationSettings.Language && this.textGenerationSettings.Language.ID > 1) {
            translateTo =
                `${this.textGenerationSettings.Language?.IsDialect ? 'dialekten ' : ''}` +
                this.textGenerationSettings.Language.Name;
            const translated = await this.aiTranslate(
                titles.join(', ') + ', ' + commentOrder.join(', '),
                translateTo,
                'Svaret må være en komma-separert liste uten linjeskift',
            );
            if (translated) titles = translated.toString().split(', ');
        }

        // Introduction
        if (this.textGenerationSettings.AddIntroText) {
            let comment = this.financialReportCommentService
                .templates()
                .find((t) => t.IsSystemTemplate === 1 && t.TemplateType === TemplateType.Intro).Text;
            comment = this.convertMacros(comment);

            this.pushComments(comment + '\n\n');
        }

        try {
            // Comment each KPI
            for (var i = 0; i < commentOrder.length; i++) {
                const kpi = this.kpiList.find((k) => k.name == commentOrder[i]);
                if (!kpi || !kpi.value) {
                    continue;
                }

                const translatedTitle = titles.length > i + offset ? titles[i + offset] : kpi.label;

                const periodInfo =
                    this.kpiMonths.to < 12
                        ? `for de ${this.kpiMonths.to} første månedene i ${this.currentYear}`
                        : `for ${this.currentYear}`;

                const prompt =
                    `Du er regnskapsfører.` +
                    ` Lag en ${this.textGenerationSettings.ShortComments ? 'kort ' : ''}kommentar til følgende fakta` +
                    ` ${periodInfo}` +
                    `${translateTo ? ` på ${translateTo.toLocaleLowerCase()}${this.textGenerationSettings.Language.Example ?? ''}` : ''}` +
                    `: ${this.buildCompactKpiText(kpi, this.textGenerationSettings?.Language?.IncludeCurrency ?? false)}`;

                const result = await this.aiGenerateService
                    .generate(prompt, this.textGenerationSettings?.Language.Temperature ?? 20)
                    .toPromise();

                let generatedText;
                if (result?.choices) {
                    generatedText = result?.choices[0]?.message?.content;
                }

                if (this.cancel) {
                    this.generating = false;
                    return;
                }

                if (generatedText) {
                    this.pushComments('# ' + translatedTitle, generatedText + '\n\n');
                }

                numberOfSuggestions++;
            }

            if (numberOfSuggestions === 0 && commentOrder.length > 0) {
                this.toastService.addToast(
                    'Fant ingen nøkkeltall å basere forslag på. Prøv en periode med regnskapstall.',
                );
            }

            // Closing comments
            if (this.textGenerationSettings.AddExtroText) {
                let comment = this.financialReportCommentService
                    .templates()
                    .find((t) => t.IsSystemTemplate === 1 && t.TemplateType === TemplateType.Summary).Text;

                this.pushComments('<br>' + comment + '\n\n');
            }
        } finally {
            // Ensure that the user gets out of the busy state whether there is an error or not
            this.generating = false;
        }
    }

    async aiTranslate(text: string, lang: string, sideComment?: string, isDialect = false) {
        const prompt = `Bruk ${lang.toLocaleLowerCase()}${sideComment ? ` (${sideComment})` : ''} og oversett dette:${text}`;
        const translated = await this.aiGenerateService.generate(prompt, 0).toPromise();

        const generatedText = translated?.choices[0]?.message?.content;
        return generatedText ?? text;
    }

    openTextSettings(continueWithGeneration = false) {
        const options = { data: this.textGenerationSettings };
        this.uniModalService.open(TextGenerationSettingsModal, options).onClose.subscribe((output) => {
            if (output && continueWithGeneration) {
                if (!this.textGenerationSettings.Language) {
                    this.textGenerationSettings.Language = TextGenerationLanguages[0];
                }
                this.startTextGeneration();
            }
        });
    }

    openTemplateImport() {
        const editorContent = <HTMLElement>this.editor.nativeElement.innerHTML;

        // if the editor contains content, then store the cursor selection
        if (editorContent) {
            this.storeSelection();
        }

        const options = { data: this.financialReportCommentService.templates() };
        this.uniModalService.open(TextTemplatesImportModal, options).onClose.subscribe((templates) => {
            if (templates) {
                // restore selection inside editor to insert where the user had the cursor
                if (editorContent) {
                    this.restoreSelection();
                }

                templates.forEach((template) => {
                    const convertedTemplate = this.convertMacros(template);
                    this.insertIntoEditor(convertedTemplate, true, false);
                    this.insertIntoEditor('<br>', true, false);
                });
            }
        });
    }

    convertMacros(text: string) {
        if (text.includes('{{ periodEndDate }}')) {
            const periodEndDate = new Date(this.currentYear, this.kpiMonths.to, 0);
            text = text.replaceAll('{{ periodEndDate }}', periodEndDate.toLocaleDateString());
        }

        return text;
    }

    clearEditor() {
        if (!this.editor) return;
        const el = <HTMLElement>this.editor.nativeElement;
        el.innerHTML = '';
    }

    toggleStyle(textStyle: 'bold' | 'italic' | 'underline') {
        try {
            document.execCommand(textStyle);
        } catch {}
    }

    getUpdatedComments() {
        return this.loadFromEditor();
    }

    checkForChanges() {
        this.hasChanges = this.analyzeChanges();
    }

    async onSaveClick() {
        this.saving = true;
        await this.saveComments()
            .toPromise()
            .catch((err) => this.errorService.handle(err));
        this.saving = false;
    }

    tryUseKpi(kpi: KpiMeta) {
        this.insertIntoEditor(this.buildKpiText(kpi), true, false);
    }

    buildKpiText(kpi: KpiMeta) {
        const tValue = this.numberFormat.asNumber(kpi.value, { decimalLength: 0 });
        const tValueLastYear = kpi.valueLastYear
            ? this.numberFormat.asNumber(kpi.valueLastYear, { decimalLength: 0 })
            : '';
        const tChange = this.numberFormat.asNumber(kpi.value - (kpi.valueLastYear ?? 0), { decimalLength: 0 });
        const tChangetext = tValueLastYear ? `Dette er en endring på ${tChange}.` : '';
        return `${kpi.label} er på ${tValue} ${tValueLastYear ? ` mot ${tValueLastYear} i fjor. ${tChangetext}` : ''}`;
    }

    buildCompactKpiText(kpi: KpiMeta, includeNOK = false) {
        const tValue = this.numberFormat.asNumber(kpi.value, { decimalLength: 0 });
        const tValueLastYear = kpi.valueLastYear
            ? this.numberFormat.asNumber(kpi.valueLastYear, { decimalLength: 0 })
            : '';
        const tChange = this.numberFormat.asNumber(kpi.value - (kpi.valueLastYear ?? 0), { decimalLength: 0 });
        const tChangetext = tValueLastYear ? `Endring: ${tChange}.` : '';
        return `${kpi.label}: ${tValue}${includeNOK ? ' NOK' : ''} ${tValueLastYear ? ` mot ${tValueLastYear} i fjor. ${tChangetext}` : ''}`;
    }

    deleteEditorContent() {
        const modalOptions = {
            header: 'Tøm kommentar',
            message: 'Ønsker du å fjerne all tekst fra kommentarfeltet?',
            buttonLabels: {
                accept: 'Tøm',
                cancel: 'Avbryt',
            },
        };

        this.uniModalService
            .confirm(modalOptions)
            .onClose.pipe(
                switchMap((result) => {
                    if (result === ConfirmActions.ACCEPT) {
                        this.clearEditor();
                        return of(true);
                    }

                    return of(false);
                }),
                switchMap((cleared) => (cleared ? this.saveComments() : of(false))),
            )
            .toPromise()
            .catch((err) => {
                this.errorService.handle(err);
            });
    }

    private async loadKpis() {
        this.kpiMonths = this.smartDetectMonths(this.id);

        this.loadingKpi = true;
        const data = await this.accountService
            .GetFinancialKpi(this.id, this.kpiMonths.from, this.kpiMonths.to)
            .toPromise();
        this.loadingKpi = false;

        // Map values into objects with norwegian text and presign-conversion
        const all = Object.keys(data).map((p) => {
            const def = FinancialKpiDefinitions[p];
            return {
                name: p,
                value: data[p] * (def.sign ?? 1),
                label: def.label ?? p,
                priority: def.priority,
                valueLastYear: 0,
                title: '',
                icon: '',
                trendColor: '',
                definition: def,
            };
        });

        // Combine last-year with current year
        all.map((p) => {
            if (p.name.endsWith('LastYear')) {
                const pfx = p.name.substring(0, p.name.length - 8);
                const pair = all.find((x) => x.name.startsWith(pfx));
                if (pair) {
                    pair.valueLastYear = p.value;
                    pair.title = `Forrige år: ${this.numberFormat.asNumber(p.value, { decimalLength: 0 })}`;
                    const isIncrement = pair.value > pair.valueLastYear;
                    const isDecrement = pair.value < pair.valueLastYear;
                    pair.icon = pair.valueLastYear
                        ? isIncrement
                            ? 'trending_up'
                            : isDecrement
                              ? 'trending_down'
                              : 'trending_flat'
                        : '';
                    if (pair.icon) {
                        pair.trendColor =
                            (isIncrement && pair.definition.isCost) || (isDecrement && !pair.definition.isCost)
                                ? 'color-bad'
                                : isDecrement || isIncrement
                                  ? 'color-good'
                                  : '';
                    }
                }
            }
        });

        // Sort and filter away YTD and LastYear
        this.kpiList = all
            .sort((a, b) => (a.priority > b.priority ? 1 : a.priority < b.priority - 1 ? -1 : 0))
            .filter((item) => item.name.indexOf('YTD') < 0 && item.name.indexOf('LastYear') < 0 && !!item.value);
    }

    private analyzeChanges(): boolean {
        if (!this.currentYear) return false;
        const updatedComments = this.loadFromEditor();
        if (updatedComments.length != this.comments.length) {
            return true;
        }
        for (var i = 0; i < this.comments.length; i++) {
            const oldComment = this.comments[i];
            const newComment = updatedComments[i];
            if (oldComment.Text != newComment.Text) {
                return true;
            }
        }
        return false;
    }

    private mergeNewCommentsIntoExisting(): FinancialReportComment[] {
        const edited = this.loadFromEditor(true);
        if (this.comments.length === 0) return edited;
        for (let i = 0; i < this.comments.length; i++) {
            const comment = this.comments[i];
            if (edited.length > i) {
                comment.Text = edited[i].Text;
            } else {
                comment.Deleted = true;
            }
        }
        return this.comments;
    }

    private loadFromEditor(convertDivs = false): FinancialReportComment[] {
        const editor = this.editor.nativeElement;
        let text = editor.innerHTML;
        if (convertDivs) {
            if (text && text.indexOf('<div')) {
                text = text.replace(new RegExp('<div>', 'g'), "<p style='margin:0'>");
                text = text.replace(new RegExp('</div>', 'g'), '</p>');
            }
        }

        const comment = new FinancialReportComment();
        comment.Text = text;
        comment.EntityID = this.id;
        comment.EntityType = 'financialreport';
        comment.CommentType = CommentType.Comment;

        // return [ { Text: text } ];
        return [comment];
    }

    private loadAndShowComments() {
        this.loading = true;
        return this.loadComments().subscribe(
            (comments) => {
                this.comments = comments;
                this.hasChanges = false;
                this.showComments(comments);
            },
            (err) => this.errorService.handle(err),
            () => (this.loading = false),
        );
    }

    private loadComments(): Observable<FinancialReportComment[]> {
        if (this.id) {
            this.currentYear = this.id;
            return this.financialReportCommentService.getAll('financialreport', this.id, undefined, true);
        }
        return of([]);
    }

    private showComments(comments: FinancialReportComment[]) {
        if (!this.editor) return;
        const el = <HTMLElement>this.editor.nativeElement;
        el.innerHTML = comments.length > 0 ? comments[0].Text : '';

        // Put actual html back into Text to make sure we detect changes correctly
        if (comments.length > 0) {
            comments[0].Text = el.innerHTML;
        }
    }

    // Prevent user from pasting formatted text
    @HostListener('paste', ['$event']) onPaste(e: any) {
        if (!e.target.isContentEditable) return;
        const items = e.clipboardData.items;
        for (const item of items) {
            if (item.type.indexOf('text/plain') === 0) {
                item.getAsString((res) => {
                    if (res) {
                        this.insertIntoEditor(res);
                    }
                });
            }
        }
        e.preventDefault();
    }

    private pushComments(...text: string[]) {
        text.forEach((t) => this.insertIntoEditor(t, true, true));
    }

    private insertIntoEditor(text: string, scrollIntoView = false, appendLast = false) {
        const editor: HTMLElement = this.editor.nativeElement;

        const sel = document.getSelection();
        const newElement = this.createCommentElement(text);
        let current = <HTMLElement>sel.focusNode;

        if (appendLast) {
            current = this.editor.nativeElement;
            current = <HTMLElement>(
                (current.childNodes.length
                    ? current.childNodes.item(current.childNodes.length - 1)
                    : <HTMLElement>sel.focusNode)
            );
        }

        if (current && this.isDecendantNodeOf(current, editor)) {
            if (sel.type === 'Range') {
                const nRange = sel.rangeCount;
                for (let i = 0; i < nRange; i++) {
                    if (i == 0) {
                        sel.getRangeAt(i).deleteContents();
                        sel.getRangeAt(i).insertNode(newElement);
                        break;
                    }
                }
            } else {
                current.after(newElement);
            }
        } else {
            editor.prepend(newElement);
        }

        this.hasChanges = true;

        if (scrollIntoView) {
            newElement.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
        }
    }

    isDecendantNodeOf(el: HTMLElement, parent: HTMLElement) {
        if (el && el.parentElement && el.parentElement === parent) return true;
        return el && el.parentElement ? this.isDecendantNodeOf(el.parentElement, parent) : false;
    }

    private createCommentElement(text: string): HTMLElement {
        let par: HTMLElement = document.createElement('p');
        let actualText = this.convertNorwegianCharacterCodes(text);
        if (text.startsWith('# ')) {
            par.appendChild(document.createElement('br'));
            par.appendChild(newElement('B', '', newElement('U', actualText.substring(2))));
        } else if (text.startsWith('## ')) {
            par.appendChild(document.createElement('br'));
            par.appendChild(newElement('B', actualText.substring(2)));
        } else {
            par.innerHTML = actualText;
        }
        par.style.margin = '0';
        return par;
    }

    private convertNorwegianCharacterCodes(value: string): string {
        if (value && value.length > 0) {
            const characterMap = {
                '&#230;': 'æ',
                '&#248;': 'ø',
                '&#229;': 'å',
                '&#198;': 'Æ',
                '&#216;': 'Ø',
                '&#197;': 'Å',
            };
            if (value.indexOf('&#') >= 0) {
                let output = '' + value;
                for (let key in characterMap) {
                    output = output.replace(new RegExp(key, 'g'), characterMap[key]);
                }
                return output;
            }
        }
        return value;
    }

    // store the current selection inside the text editor
    private storeSelection() {
        this.storedEditorSelection = document?.getSelection()?.getRangeAt(0)?.cloneRange();
    }

    // restore the stored selection
    private restoreSelection() {
        if (this.storedEditorSelection) {
            const sel = document.getSelection();
            sel?.removeAllRanges();
            sel?.addRange(this.storedEditorSelection);
        }
    }

    smartDetectMonths(year: number): { from: number; to: number; label: string } {
        let months = this.getMonthsFromPeriod();
        if (!months) {
            if (months) return months;
            months = { from: 1, to: 12, label: '' };
            const today = new Date();
            if (year != today.getFullYear()) return months;
            months.to = today.getMonth();
        }
        if (months.to == 0) months.to++; // If in january we allow it since 0 is not a good period
        if (months.to < 12)
            months.label = new Date(year, months.to - 1, 1).toLocaleString('default', { month: 'long' });
        return months;
    }

    getMonthsFromPeriod(): { from: number; to: number; label: string } {
        const result = { from: 0, to: 0, label: '' };
        for (let param of this.parameters) {
            switch (param.name) {
                case 'fp':
                case 'fromperiod':
                    result.from = <number>param.value;
                    break;
                case 'tp':
                case 'toperiod':
                    result.to = <number>param.value;
                    break;
            }
        }
        if (result.from > 0 && result.to > 0) {
            return result;
        }
    }
}

function newElement(tagName: string, text?: string, subElement?: HTMLElement): HTMLElement {
    const nd = document.createElement(tagName);
    if (text) {
        nd.innerText = text;
    }
    if (subElement) {
        nd.appendChild(subElement);
    }
    return nd;
}
