[
    {
        "FieldType": 3,
        "EntityType": "",
        "Property": "ProductID",
        "Label": "Produkt",
        "LookupField": true,
        "FeaturePermission": "ui.accounting.agriculture",
        "Url": "/products?filter=StatusCode%20eq%2035001",
        "ReadOnly": true,
        "Classes": "full-width",
        "Options": {
            "hideDeleteButton": true,
            "searchable": true,
            "valueProperty": "ID",
            "itemTemplate": ["PartName", "Name"]
        }
    },
    {
        "FieldType": 6,
        "EntityType": "",
        "Property": "NumberOfItems",
        "Label": "Antall I",
        "LookupField": false,
        "ReadOnly": true,
        "FeaturePermission": "ui.accounting.agriculture"
    },
    {
        "FieldType": 3,
        "EntityType": "",
        "Property": "UnitOfMeasureID",
        "Label": "Enhet I",
        "LookupField": false,
        "FeaturePermission": "ui.accounting.agriculture",
        "ReadOnly": true,
        "Url": "/unitofmeasures",
        "Options": {
            "hideDeleteButton": true,
            "searchable": true,
            "valueProperty": "ID",
            "itemTemplate": ["Symbol", "SingularName"]
        }
    },
    {
        "FieldType": 6,
        "EntityType": "",
        "Property": "CustomNumberOfItemsSecondary",
        "Label": "Antall II",
        "LookupField": false,
        "FeaturePermission": "ui.accounting.agriculture"
    },
    {
        "FieldType": 3,
        "EntityType": "",
        "Property": "CustomUnitOfMeasureIDSecondary",
        "Label": "Enhet II",
        "LookupField": true,
        "FeaturePermission": "ui.accounting.agriculture",
        "Url": "/unitofmeasures",
        "Options": {
            "hideDeleteButton": true,
            "searchable": true,
            "valueProperty": "ID",
            "itemTemplate": ["Symbol", "SingularName"]
        }
    },
    {
        "FieldType": 3,
        "EntityType": "",
        "Property": "CustomProductQuality",
        "Label": "Produktkvalitet",
        "LookupField": true,
        "FeaturePermission": "ui.accounting.agriculture",
        "Options": {
            "hideDeleteButton": true,
            "searchable": false,
            "valueProperty": "ID",
            "displayProperty": "Name",
            "source": [
                { "ID": 0, "Name": "God" },
                { "ID": 1, "Name": "Nokså god" },
                { "ID": 2, "Name": "Dårlig" }
            ]
        }
    },
    {
        "FieldType": 3,
        "EntityType": "",
        "Property": "CustomProductAddition",
        "Label": "Tillegg eller trekk",
        "LookupField": true,
        "FeaturePermission": "ui.accounting.agriculture",
        "Options": {
            "hideDeleteButton": true,
            "searchable": false,
            "valueProperty": "ID",
            "displayProperty": "Name",
            "source": [
                { "ID": 0, "Name": "Tillegg" },
                { "ID": 1, "Name": "Trekk" }
            ]
        }
    },
    {
        "FieldType": 0,
        "EntityType": "",
        "Property": "CustomProductDetail",
        "Label": "Produktdetalj",
        "LookupField": true,
        "FeaturePermission": "ui.accounting.agriculture"
    }
]
